import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import DropdownMenu from 'components/DropdownMenu/DropdownMenu'

import { IconDetails } from 'svg'

import CloneSVG from './svg/Clone'
import DownSVG from './svg/Down'
import UpSVG from './svg/Up'
import RemoveSVG from './svg/Remove'
import EditSVG from './svg/Edit'

import Item from './components/Item/Item'

import styles from './ActionsDropdown.module.scss'

export const TYPES = {
    PAGE: 'PAGE',
    ROUND: 'ROUND',
}

const ActionsDropdown = ({ type, index, arrayLength, methods }) => {
    const { t } = useTranslation()
    const [items, setItems] = useState([])

    useEffect(() => {
        if (type === TYPES.PAGE) {
            const _items =
                index !== 0
                    ? [
                          <Item text={t('Rename')} icon={EditSVG} onClick={methods.onClickRename} />,
                          index > 1 && (
                              <Item
                                  text={t('Move up')}
                                  icon={UpSVG}
                                  onClick={() => methods.onChangePosition(index, index - 1)}
                              />
                          ),
                          index !== arrayLength - 1 && (
                              <Item
                                  text={t('Move down')}
                                  icon={DownSVG}
                                  onClick={() => methods.onChangePosition(index, index + 1)}
                              />
                          ),
                          <Item text={t('Clone')} icon={CloneSVG} onClick={methods.onClone} />,
                          arrayLength > 1 && <Item text={t('Remove')} icon={RemoveSVG} onClick={methods.onRemove} />,
                      ]
                    : [<Item text={t('Clone')} icon={CloneSVG} onClick={methods.onClone} />]
            setItems(_items)
        }

        if (type === TYPES.ROUND) {
            const _items = [
                <Item text={t('Rename')} icon={EditSVG} onClick={methods.onClickRename} />,
                index > 0 && (
                    <Item text={t('Move up')} icon={UpSVG} onClick={() => methods.onChangePosition(index, index - 1)} />
                ),
                index !== arrayLength - 1 && (
                    <Item
                        text={t('Move down')}
                        icon={DownSVG}
                        onClick={() => methods.onChangePosition(index, index + 1)}
                    />
                ),
                <Item text={t('Clone')} icon={CloneSVG} onClick={methods.onClone} />,
                arrayLength > 1 && <Item text={t('Remove')} icon={RemoveSVG} onClick={methods.onRemove} />,
            ]

            setItems(_items)
        }
    }, [index, arrayLength, methods])

    return (
        <DropdownMenu
            className={classNames('actionsDropdown', styles.actionsDropdown)}
            actionHtml={<IconDetails />}
            items={items}
        />
    )
}

export default ActionsDropdown
