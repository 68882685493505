import i18n from 'i18n'

import crosswordIcon from 'components/Modal/components/Body/components/SideNav/i/crossword.svg'
import resultsIcon from 'components/Modal/components/Body/components/SideNav/i/results.svg'

export const TABS = {
    ITEMS: 1,
    FINAL: 2,
}

export const TABS_ARRAY = [
    {
        id: 1,
        label: i18n.t('Words, clues'),
        icon: crosswordIcon,
    },
    {
        id: 2,
        label: i18n.t('Final screen'),
        icon: resultsIcon,
    },
]

export const ORIENTATIONS = {
    ACROSS: 'ACROSS',
    DOWN: 'DOWN',
}

export const MIN_ITEMS_COUNT = 2
export const MAX_ITEMS_COUNT = 50

export const MIN_ANSWERS_TO_SHOW_LOADER = 21

export const MAX_ANSWER_LENGTH = 30
