import store from 'store'
import i18n from 'i18n'
import { UTILS__VALIDATOR__is_value } from 'utils/validator'

import { MAIN_SERVER } from './instance'

export const GET_PROJECTS = async params => {
    return MAIN_SERVER({
        method: 'get',
        url: `/projects`,
        params: { ...params, size: 10, sort: 'updated,desc' },
    })
}

export const GET_PROJECT_PDF = async projectId => {
    const data = await MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/pdf`,
        responseType: 'blob',
    })
    return window.URL.createObjectURL(new Blob([data]))
}

export const GET_PROJECTS_COUNT = async obj => {
    const params = {}

    if (UTILS__VALIDATOR__is_value(obj.authorId)) {
        params.authorId = obj.authorId
    }
    if (UTILS__VALIDATOR__is_value(obj.organizationId)) {
        params.organizationId = obj.organizationId
    }

    return MAIN_SERVER({
        method: 'get',
        url: `/projects/count-by-status`,
        params,
    })
}

export const GET_PROJECT = async projectId => {
    return MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}`,
    })
}

export const CLONE_PROJECT = async (projectId, folderId, organizationId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/projects/${projectId}/clone`,
        params: { folderId, organizationId },
        data: {},
    })

export const DELETE_PROJECT = async projectId =>
    MAIN_SERVER({
        method: 'delete',
        url: `/projects/${projectId}`,
    })

export const UPDATE_PROJECT = async (projectId, data) =>
    MAIN_SERVER({
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 30000,
        url: `/projects/${projectId}`,
        data,
    })

export const START_MULTIPLAYER_GAME = async (projectId, gameId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/projects/${projectId}/multiplayer-games/${gameId}/start`,
    })

export const CREATE_MULTIPLAYER_GAME = async (projectId, data) =>
    MAIN_SERVER({
        method: 'post',
        url: `/projects/${projectId}/multiplayer-games/create`,
        data,
    })

export const MOVE_PROJECT_TO_FOLDER = async (projectId, folderId) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/projects/${projectId}/change-folder`,
        data: { id: folderId },
    })

export const PUBLISH = async (id, payload = {}) => {
    const { language } = store.getState()

    return MAIN_SERVER({
        method: 'patch',
        url: `/projects/${id}/publish`,
        timeout: 90000,
        data: {
            ...payload,
            languageCode: language.toUpperCase(),
        },
    })
}

export const GET_DEFAULT_PREVIEW_IMAGE = async () =>
    MAIN_SERVER({
        method: 'get',
        headers: {
            'Content-Type': 'text/plain',
        },
        url: '/projects/preview/default-preview-image',
    })

export const SET_PROJECT_PREVIEW_IMAGE = async (projectId, imageId) =>
    MAIN_SERVER({
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        url: `/projects/${projectId}/preview-image/${imageId}`,
    })

export const SET_DEFAULT_PREVIEW_IMAGE = async projectId =>
    MAIN_SERVER({
        method: 'patch',
        url: `/projects/${projectId}/default-preview-image`,
        data: {},
    })

export const CHECK_CUSTOM_URL = async value => {
    const data = {}

    if (UTILS__VALIDATOR__is_value(value)) {
        data.customUrl = value
    }

    return MAIN_SERVER({
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 30000,
        url: '/projects/custom-url/check',
        data,
    })
}

export const SET_CUSTOM_URL = async (projectId, customUrl) => {
    return MAIN_SERVER({
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 30000,
        url: `/projects/${projectId}/custom-url/reserve`,
        data: { customUrl },
    })
}

export const SEND_CONSTRAINT = async (actionTypeName, projectId, blockId, body = {}) =>
    MAIN_SERVER({
        method: 'put',
        url: `/actions/constraints/${actionTypeName}/project-id/${projectId}/block-id/${blockId}`,
        data: {
            actionTypeName,
            projectId,
            blockId,
            ...body,
        },
    })

export const REMOVE_CONSTRAINT = async (actionTypeName, projectId, blockId) => {
    try {
        await MAIN_SERVER({
            method: 'delete',
            url: `/actions/constraints/${actionTypeName}/project-id/${projectId}/block-id/${blockId}`,
        })
    } catch (err) {
        if (err.response) {
            if (err.response.status === 404) return
        }
        throw err
    }
}

export const SET_EMAIL_INTEGRATION = async (email, projectId) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/integrations/EMAIL/projects/${projectId}`,
        data: [
            {
                key: 'NOTIFICATION_EMAIL',
                value: email,
            },
        ],
    })

export const DISABLE_EMAIL_INTEGRATION = async projectId =>
    MAIN_SERVER({
        method: 'patch',
        url: `/integrations/EMAIL/projects/${projectId}/disable`,
        data: {},
    })

export const CREATE_PROJECT_FROM_TEMPLATE = async (templateId, selectedOrganizationId, folderId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/projects/from-template`,
        data: {
            templateId,
            name: i18n.t('My project'),
            title: i18n.t('Interacty – engaging content'),
            description: i18n.t('Try it yourself!'),
            organizationId: selectedOrganizationId,
            folderId,
        },
    })

export const CHANGE_VISIBILITY = ({ projectId, isHidden }) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/projects/${projectId}/hidden`,
        data: {
            hidden: isHidden,
        },
    })

export const GET_PROJECT_STATISTIC_REMOVE_INTERVAL = async projectId =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/removalInterval`,
    })

export const SET_PROJECT_STATISTIC_REMOVE_INTERVAL = async (projectId, periodInDays) =>
    MAIN_SERVER({
        method: 'post',
        url: `/projects/${projectId}/statistics/removalInterval`,
        data: {
            periodInDays,
        },
    })

export const UPDATE_PROJECT_STATISTIC_REMOVE_INTERVAL = async (projectId, taskId, periodInDays) =>
    MAIN_SERVER({
        method: 'put',
        url: `/projects/${projectId}/statistics/removalInterval/${taskId}`,
        data: {
            periodInDays,
        },
    })

export const DELETE_PROJECT_STATISTIC_REMOVE_INTERVAL = async (projectId, taskId) =>
    MAIN_SERVER({
        method: 'delete',
        url: `/projects/${projectId}/statistics/removalInterval/${taskId}`,
    })

export const DELETE_PROJECT_STATISTIC = async projectId =>
    MAIN_SERVER({
        method: 'delete',
        url: `/projects/${projectId}/statistics`,
    })
