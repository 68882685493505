export const TIME_IS_UP_INIT_STATE = {
    header: 'Time is up!',
    description: 'You will be lucky next time. Try again!',
    imageSrc: '',
    imageDisclaimer: '',
    isActionButton: false,
    actionButtonText: '',
    actionButtonLink: '',
    isOpenLinkInNewTab: true,
}
