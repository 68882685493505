import React from 'react'

import Tooltip from 'components/Tooltip/Tooltip.jsx'

class RadioButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    changeValue = value => {
        const { onUpdate, name, id } = this.props

        onUpdate(id, { [name]: value })
    }

    render() {
        const {
            value,
            tooltipText,
            schema: { label = '', payload },
        } = this.props

        let items = []
        if (payload && payload.items) {
            items = payload.items
        }
        let style = {}
        if (payload && payload.flexDirection) {
            style = { ...style, flexDirection: payload.flexDirection }
        }
        return (
            <div className="control-box">
                {label.length ? (
                    <p className="label">
                        {label}
                        {<Tooltip tooltipText={tooltipText} />}
                    </p>
                ) : null}
                <div className="content radio" style={style}>
                    {items.map(item => (
                        <div
                            key={item.value}
                            className={`radio-option ${value.value === item.value ? 'is-checked' : ''}`}
                            onClick={() => this.changeValue(item)}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default RadioButton
