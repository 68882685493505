import { getMoment, FORMATS } from 'utils/dateTime'

import store from 'store'

const getFormatValue = (int, digits, high_digits_value, units, high_units) => {
    const digits_rounded = parseInt((digits * 10).toFixed(0))
    const high_round = digits_rounded === 10

    if (digits_rounded && !high_round) {
        return `${int},${digits_rounded} ${units}`
    } else {
        int += high_round ? 1 : 0
        return int !== high_digits_value ? `${int} ${units}` : `1 ${high_units}`
    }
}

export const formattedTime = (seconds = 0, lng = store.getState().language) => {
    const moment_duration = getMoment.duration(seconds, 'seconds')

    const _days = moment_duration.days()
    const _hours = moment_duration.hours()
    const _minutes = moment_duration.minutes()
    const _seconds = moment_duration.seconds()

    if (_days) {
        return getFormatValue(
            _days,
            (_hours * 3600 + _minutes * 60 + _seconds) / 86400,
            7,
            FORMATS.shortCycle.day[lng],
            FORMATS.shortCycle.weeks[lng],
        )
    }
    if (_hours)
        return getFormatValue(
            _hours,
            (_minutes * 60 + _seconds) / 3600,
            24,
            FORMATS.shortCycle.hour[lng],
            FORMATS.shortCycle.day[lng],
        )
    if (_minutes)
        return getFormatValue(
            _minutes,
            _seconds / 60,
            60,
            FORMATS.shortCycle.minutes[lng],
            FORMATS.shortCycle.hour[lng],
        )

    return seconds ? `${seconds} ${FORMATS.shortCycle.seconds[lng]}` : 0
}
