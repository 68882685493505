import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import Modal from '../../../../../../components/Modal/Modal'
import campaignsImg from '../../i/internal_pages_campaigns.png'

import './AddInternalPageModal.scss'

const AddInternalPageModal = ({ isOpen, onAddPage, onClose }) => {
    const { t } = useTranslation()

    const onAction = () => {
        onAddPage()
        onClose()
    }

    return isOpen ? (
        <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={onClose} isShowCloseIcon>
            <div className="add-internal-page-modal">
                <img className="add-internal-page-modal__img" src={campaignsImg} alt="internal_pages_campaigns" />
                <h2 className="add-internal-page-modal__title">{t('Game campaigns')}</h2>
                <p className="add-internal-page-modal__description">
                    {t('By adding pages, you can create multi-level games, quest sequences, or tutorials.')}
                    <br />
                    <br />
                    <Trans
                        i18nKey="To customize the navigation logic for buttons in the project, specify the desired internal pages instead of external links. Find out more about the possibilities of game campaigns in our <lnk>blog</lnk>"
                        components={{
                            lnk: (
                                <a
                                    href="https://help.interacty.me/en/content/how-to-add-a-new-page"
                                    className="add-internal-page-modal__link"
                                    target="_blank"
                                />
                            ),
                        }}
                    />
                </p>
                <div className="add-internal-page-modal__buttons">
                    <button
                        className="add-internal-page-modal____button button"
                        data-size="lg"
                        data-variant="secondary"
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </button>
                    <button
                        className="add-internal-page-modal____button button"
                        data-size="lg"
                        data-variant="primary"
                        onClick={onAction}
                    >
                        {t('Create page')}
                    </button>
                </div>
            </div>
        </Modal>
    ) : null
}

export default AddInternalPageModal
