export const IMAGE_SIZES = {
    fullWidth: {
        value: 'fullWidth',
        label: 'Full width',
    },
    compact: {
        value: 'compact',
        label: 'Compact',
    },
    custom: {
        value: 'custom',
        label: 'Custom',
    },
}
