const ANSWER_KEY = 'answer'
const START_X_KEY = 'startX'
const START_Y_KEY = 'startY'
const POSITION_KEY = 'position'
const INCLUDED_KEY = 'isIncluded'
const ORIENTATION_KEY = 'orientation'
const ORIENTATION_VALUES = {
    ACROSS: 'ACROSS',
    DOWN: 'DOWN',
}

const FINAL_TABLE_KEY = 'table'
const FINAL_RESULT_KEY = 'results'
const FINAL_ROWS_KEY = 'rows'
const FINAL_COLS_KEY = 'cols'

// Math functions
const distance = (x1, y1, x2, y2) => Math.abs(x1 - x2) + Math.abs(y1 - y2)
const weightedAverage = (weights, values) => {
    let temp = 0

    for (let k = 0; k < weights.length; k++) {
        temp += weights[k] * values[k]
    }

    return temp
}

// Component scores
// 1. Number of connections
const computeScore1 = (connections, word) => connections / (word.length / 2)
// 2. Distance from center
const computeScore2 = (rows, cols, i, j) => {
    return 1 - distance(rows / 2, cols / 2, i, j) / (rows / 2 + cols / 2)
}
// 3. Vertical versus horizontal orientation
const computeScore3 = (a, b, verticalCount, totalCount) => {
    if (verticalCount > totalCount / 2) {
        return a
    } else if (verticalCount < totalCount / 2) {
        return b
    } else {
        return 0.5
    }
}
// 4. Word length
const computeScore4 = (val, word) => word.length / val

// Word functions
const addWord = (best, words, table) => {
    const word = best[1]
    const index = best[2]
    const bestI = best[3]
    const bestJ = best[4]
    const bestO = best[5]

    words[index][START_X_KEY] = bestJ + 1
    words[index][START_Y_KEY] = bestI + 1

    if (bestO === 0) {
        for (let k = 0; k < word.length; k++) {
            table[bestI][bestJ + k] = word.charAt(k)
        }
        words[index][ORIENTATION_KEY] = ORIENTATION_VALUES.ACROSS
    } else {
        for (let k = 0; k < word.length; k++) {
            table[bestI + k][bestJ] = word.charAt(k)
        }
        words[index][ORIENTATION_KEY] = ORIENTATION_VALUES.DOWN
    }
}
const assignPositions = words => {
    const includedWords = words.filter(word => word[INCLUDED_KEY])

    if (!includedWords.length) return

    const positions = []
    const minY = includedWords.reduce((prev, cur) => (cur[START_Y_KEY] < prev[START_Y_KEY] ? cur : prev))[START_Y_KEY]
    const maxY = includedWords.reduce((prev, cur) => (cur[START_Y_KEY] > prev[START_Y_KEY] ? cur : prev))[START_Y_KEY]
    for (let startY = minY; startY <= maxY; startY++) {
        const sameStartY = includedWords.filter(word => word[START_Y_KEY] === startY)
        if (!sameStartY.length) continue
        const minX = sameStartY.reduce((prev, cur) => (cur[START_X_KEY] < prev[START_X_KEY] ? cur : prev))[START_X_KEY]
        const maxX = sameStartY.reduce((prev, cur) => (cur[START_X_KEY] > prev[START_X_KEY] ? cur : prev))[START_X_KEY]
        for (let startX = minX; startX <= maxX; startX++) {
            const sameStartX = sameStartY.filter(word => word[START_X_KEY] === startX)
            if (!sameStartX.length) continue
            positions.push({ startX, startY })
        }
    }

    for (let index in words) {
        const word = words[index]
        if (!word[INCLUDED_KEY]) continue
        const wordPositionIndex = positions.findIndex(
            position => position.startX === word[START_X_KEY] && position.startY === word[START_Y_KEY],
        )
        if (wordPositionIndex === -1) word[POSITION_KEY] = null
        else word[POSITION_KEY] = wordPositionIndex + 1
    }
}
const computeDimension = (words, factor) => {
    let temp = 0
    for (let i = 0; i < words.length; i++) {
        if (temp < words[i][ANSWER_KEY].length) {
            temp = words[i][ANSWER_KEY].length
        }
    }

    return temp * factor
}

// Table functions
const initTable = (rows, cols) => {
    const table = []
    for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
            if (j === 0) {
                table[i] = ['-']
            } else {
                table[i][j] = '-'
            }
        }
    }

    return table
}
const isConflict = (table, isVertical, character, i, j) => {
    if (character !== table[i][j] && table[i][j] !== '-') return true
    else if (table[i][j] === '-' && !isVertical && i + 1 in table && table[i + 1][j] !== '-') return true
    else if (table[i][j] === '-' && !isVertical && i - 1 in table && table[i - 1][j] !== '-') return true
    else if (table[i][j] === '-' && isVertical && j + 1 in table[i] && table[i][j + 1] !== '-') return true
    else return table[i][j] === '-' && isVertical && j - 1 in table[i] && table[i][j - 1] !== '-'
}
const attemptToInsert = (rows, cols, table, weights, verticalCount, totalCount, word, index) => {
    let bestI = 0,
        bestJ = 0,
        bestO = 0,
        bestScore = -1

    // Horizontal
    for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols - word.length + 1; j++) {
            let isValid = true,
                atleastOne = false,
                connections = 0,
                prevFlag = false

            for (let k = 0; k < word.length; k++) {
                if (isConflict(table, false, word.charAt(k), i, j + k)) {
                    isValid = false
                    break
                } else if (table[i][j + k] === '-') {
                    prevFlag = false
                    atleastOne = true
                } else {
                    if (prevFlag) {
                        isValid = false
                        break
                    } else {
                        prevFlag = true
                        connections += 1
                    }
                }
            }

            if (j - 1 in table[i] && table[i][j - 1] !== '-') {
                isValid = false
            } else if (j + word.length in table[i] && table[i][j + word.length] !== '-') {
                isValid = false
            }

            if (isValid && atleastOne && word.length > 1) {
                const tempScore1 = computeScore1(connections, word)
                const tempScore2 = computeScore2(rows, cols, i, j + word.length / 2, word)
                const tempScore3 = computeScore3(1, 0, verticalCount, totalCount)
                const tempScore4 = computeScore4(rows, word)
                const tempScore = weightedAverage(weights, [tempScore1, tempScore2, tempScore3, tempScore4])

                if (tempScore > bestScore) {
                    bestScore = tempScore
                    bestI = i
                    bestJ = j
                    bestO = 0
                }
            }
        }
    }

    // Vertical
    for (let i = 0; i < rows - word.length + 1; i++) {
        for (let j = 0; j < cols; j++) {
            let isValid = true,
                atleastOne = false,
                connections = 0,
                prevFlag = false

            for (let k = 0; k < word.length; k++) {
                if (isConflict(table, true, word.charAt(k), i + k, j)) {
                    isValid = false
                    break
                } else if (table[i + k][j] === '-') {
                    prevFlag = false
                    atleastOne = true
                } else {
                    if (prevFlag) {
                        isValid = false
                        break
                    } else {
                        prevFlag = true
                        connections += 1
                    }
                }
            }

            if (i - 1 in table && table[i - 1][j] !== '-') {
                isValid = false
            } else if (i + word.length in table && table[i + word.length][j] !== '-') {
                isValid = false
            }

            if (isValid && atleastOne && word.length > 1) {
                const tempScore1 = computeScore1(connections, word)
                const tempScore2 = computeScore2(rows, cols, i + word.length / 2, j, word)
                const tempScore3 = computeScore3(0, 1, verticalCount, totalCount)
                const tempScore4 = computeScore4(rows, word)
                const tempScore = weightedAverage(weights, [tempScore1, tempScore2, tempScore3, tempScore4])

                if (tempScore > bestScore) {
                    bestScore = tempScore
                    bestI = i
                    bestJ = j
                    bestO = 1
                }
            }
        }
    }

    if (bestScore > -1) {
        return [bestScore, word, index, bestI, bestJ, bestO]
    } else return [-1]
}
const generateTable = (table, rows, cols, words, weights) => {
    let verticalCount = 0,
        totalCount = 0

    for (let outerIndex in words) {
        let best = [-1]
        for (let innerIndex in words) {
            if (ANSWER_KEY in words[innerIndex] && !(START_X_KEY in words[innerIndex])) {
                const temp = attemptToInsert(
                    rows,
                    cols,
                    table,
                    weights,
                    verticalCount,
                    totalCount,
                    words[innerIndex][ANSWER_KEY],
                    innerIndex,
                )
                if (temp[0] > best[0]) {
                    best = temp
                }
            }
        }

        if (best[0] === -1) {
            break
        } else {
            addWord(best, words, table)
            if (best[5] === 1) {
                verticalCount += 1
            }
            totalCount += 1
        }
    }

    for (let index in words) {
        if (!(START_X_KEY in words[index])) {
            words[index][ORIENTATION_KEY] = null
        }
    }

    return { table: table, result: words }
}
const removeIsolatedWords = data => {
    const oldTable = data.table
    const words = data.result
    const rows = oldTable.length
    const cols = oldTable[0].length
    let newTable = initTable(rows, cols)

    // Draw intersections as "X"'s
    for (let wordIndex in words) {
        const word = words[wordIndex]
        if (word[ORIENTATION_KEY] === ORIENTATION_VALUES.ACROSS) {
            const i = word[START_Y_KEY] - 1
            const j = word[START_X_KEY] - 1
            for (let k = 0; k < word[ANSWER_KEY].length; k++) {
                if (newTable[i][j + k] === '-') {
                    newTable[i][j + k] = 'O'
                } else if (newTable[i][j + k] === 'O') {
                    newTable[i][j + k] = 'X'
                }
            }
        } else if (word[ORIENTATION_KEY] === ORIENTATION_VALUES.DOWN) {
            const i = word[START_Y_KEY] - 1
            const j = word[START_X_KEY] - 1
            for (let k = 0; k < word[ANSWER_KEY].length; k++) {
                if (newTable[i + k][j] === '-') {
                    newTable[i + k][j] = 'O'
                } else if (newTable[i + k][j] === 'O') {
                    newTable[i + k][j] = 'X'
                }
            }
        }
    }

    // Set orientations to null if they have no intersections
    for (let wordIndex in words) {
        const word = words[wordIndex]
        let isIsolated = true

        if (word[ORIENTATION_KEY] === ORIENTATION_VALUES.ACROSS) {
            const i = word[START_Y_KEY] - 1
            const j = word[START_X_KEY] - 1
            for (let k = 0; k < word[ANSWER_KEY].length; k++) {
                if (newTable[i][j + k] === 'X') {
                    isIsolated = false
                    break
                }
            }
        } else if (word[ORIENTATION_KEY] === ORIENTATION_VALUES.DOWN) {
            const i = word[START_Y_KEY] - 1
            const j = word[START_X_KEY] - 1
            for (let k = 0; k < word[ANSWER_KEY].length; k++) {
                if (newTable[i + k][j] === 'X') {
                    isIsolated = false
                    break
                }
            }
        }

        if (isIsolated) {
            delete words[wordIndex][START_X_KEY]
            delete words[wordIndex][START_Y_KEY]
            delete words[wordIndex][POSITION_KEY]
            delete words[wordIndex][ORIENTATION_KEY]
            words[wordIndex][INCLUDED_KEY] = false
        } else {
            words[wordIndex][INCLUDED_KEY] = true
        }
    }

    // Draw new table
    newTable = initTable(rows, cols)
    for (let wordIndex in words) {
        const word = words[wordIndex]
        if (word[ORIENTATION_KEY] === ORIENTATION_VALUES.ACROSS) {
            const i = word[START_Y_KEY] - 1
            const j = word[START_X_KEY] - 1
            for (let k = 0; k < word[ANSWER_KEY].length; k++) {
                newTable[i][j + k] = word[ANSWER_KEY].charAt(k)
            }
        } else if (word[ORIENTATION_KEY] === ORIENTATION_VALUES.DOWN) {
            const i = word[START_Y_KEY] - 1
            const j = word[START_X_KEY] - 1
            for (let k = 0; k < word[ANSWER_KEY].length; k++) {
                newTable[i + k][j] = word[ANSWER_KEY].charAt(k)
            }
        }
    }

    return { table: newTable, result: words }
}
const trimTable = data => {
    const table = data.table
    const rows = table.length
    const cols = table[0].length

    let leftMost = cols,
        topMost = rows,
        rightMost = -1,
        bottomMost = -1

    for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
            if (table[i][j] !== '-') {
                const x = j
                const y = i

                if (x < leftMost) {
                    leftMost = x
                }
                if (x > rightMost) {
                    rightMost = x
                }
                if (y < topMost) {
                    topMost = y
                }
                if (y > bottomMost) {
                    bottomMost = y
                }
            }
        }
    }

    const trimmedTable = initTable(bottomMost - topMost + 1, rightMost - leftMost + 1)
    for (let i = topMost; i < bottomMost + 1; i++) {
        for (let j = leftMost; j < rightMost + 1; j++) {
            trimmedTable[i - topMost][j - leftMost] = table[i][j]
        }
    }

    const words = data.result
    for (let entry in words) {
        if (START_X_KEY in words[entry]) {
            words[entry][START_X_KEY] -= leftMost
            words[entry][START_Y_KEY] -= topMost
        }
    }

    return {
        [FINAL_TABLE_KEY]: trimmedTable,
        [FINAL_RESULT_KEY]: words,
        [FINAL_ROWS_KEY]: Math.max(bottomMost - topMost + 1, 0),
        [FINAL_COLS_KEY]: Math.max(rightMost - leftMost + 1, 0),
    }
}
const generateSimpleTable = words => {
    const rows = computeDimension(words, 3)
    const cols = rows
    const blankTable = initTable(rows, cols)
    const table = generateTable(blankTable, rows, cols, words, [0.7, 0.15, 0.1, 0.05])
    const newTable = removeIsolatedWords(table)
    const finalTable = trimTable(newTable)
    assignPositions(finalTable[FINAL_RESULT_KEY])
    return finalTable
}

export function generateLayout(words) {
    const data = words.map(word => ({ [ANSWER_KEY]: word.toLowerCase() }))

    if (data.length < 2) throw new Error('Min length: 2')

    return generateSimpleTable(data)
}
