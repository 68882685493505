import isJSON from 'validator/lib/isJSON'
import isEmail from 'validator/lib/isEmail'

export const isValue = value => {
    try {
        return value !== void 0 && value !== null
    } catch (_) {
        return false
    }
}

export const UTILS__VALIDATOR__is_email = data => {
    return isEmail(data)
}
export const UTILS__VALIDATOR__is_value = data => {
    return isValue(data)
}
export const UTILS__VALIDATOR__is_json = data => {
    return isJSON(data)
}
export const UTILS__VALIDATOR__validate_email = email => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regexp.test(String(email).toLowerCase())
}

export const UTILS__VALIDATOR__validate_url = url => {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(String(url))
}
