import React from 'react'

import Tooltip from 'components/Tooltip/Tooltip'

const LinkWithModal = ({
    id,
    isEnableRating = false,
    value,
    tooltipText,
    tooltipCallback,
    schema: { label = '', payload },
    schemaName,
    openModalByName,
    colorTheme,
    fontFamily,
    leadFormFontFamily,
    leadFormBackgroundColor,
}) => (
    <div className="control-box">
        <span
            className="link"
            onClick={() =>
                openModalByName(payload.modalName, {
                    value,
                    blockId: id,
                    isEnableRating,
                    fieldName: schemaName,
                    colorTheme,
                    fontFamily,
                    leadFormFontFamily,
                    leadFormBackgroundColor,
                })
            }
        >
            {label}
        </span>
        <Tooltip tooltipText={tooltipText} onClick={tooltipCallback} />
    </div>
)

export default LinkWithModal
