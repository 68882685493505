import qs from 'qs'
import Cookies from 'js-cookie'

import store from 'store'

import { UTILS__VALIDATOR__is_value } from 'utils/validator'
import { sendGoogleAnalyticsEvent } from 'hooks/useGoogleAnalytics'
import AnalyticsMonitor from 'common/services/AnalyticsMonitor'

import { AUTH__LOGOUT, USER__SET_DETAILS } from 'store/actions'

import { credPassword, credType, credUsername } from './constants'
import { MAIN_SERVER } from './instance'
import { GET_DETAILS } from './user'

export const LOGIN = async (obj, userId) => {
    const headers = {
        Authorization: `${credType} ${window.btoa(`${credUsername}:${credPassword}`)}`,
    }
    if (userId) headers['X-User-Id'] = userId

    const response = await MAIN_SERVER({
        method: 'post',
        url: '/oauth/token',
        headers,
        data: qs.stringify({
            grant_type: obj.grant_type,
            username: obj.email,
            password: obj.password,
        }),
    })

    const userDetails = await GET_DETAILS()
    store.dispatch(USER__SET_DETAILS(userDetails))

    if (!userId) {
        sendGoogleAnalyticsEvent('signin')
        AnalyticsMonitor.identify(response.user.id)
    }
}

export const REGISTRATION = async obj => {
    try {
        const data = {}
        if (UTILS__VALIDATOR__is_value(obj.firstName)) {
            data.firstName = obj.firstName
        }
        if (UTILS__VALIDATOR__is_value(obj.lastName)) {
            data.lastName = obj.lastName
        }
        if (UTILS__VALIDATOR__is_value(obj.email)) {
            data.login = obj.email
        }
        if (UTILS__VALIDATOR__is_value(obj.company)) {
            data.company = obj.company
        }
        if (UTILS__VALIDATOR__is_value(obj.password)) {
            data.password = obj.password
        }
        if (UTILS__VALIDATOR__is_value(obj.role_id)) {
            data.roleInProjectId = obj.role_id
        }
        if (UTILS__VALIDATOR__is_value(obj.newsSubscription)) {
            data.newsSubscription = obj.newsSubscription
        }
        const ga = Cookies.get('_ga')
        if (typeof ga !== 'undefined') {
            data.cid = ga
        }

        return await MAIN_SERVER({
            method: 'post',
            url: '/users',
            headers: {
                'Content-Type': 'application/json',
            },
            data,
        })
    } catch (err) {
        throw err
    }
}

export const LOGOUT = async () => {
    await MAIN_SERVER({
        method: 'post',
        url: '/logout',
        data: {},
    })
    store.dispatch(AUTH__LOGOUT())
}

export const APP_SUMO_COMPLETE_REGISTRATION = ({ login, password, activationCode }) =>
    MAIN_SERVER({
        method: 'post',
        url: '/integrations/appsumo/users/password',
        data: {
            login,
            password,
            activationCode,
        },
    })

export const SOCIAL_COOKIE = (token, userId) =>
    MAIN_SERVER({
        method: 'post',
        url: '/users/social-cookie',
        headers: {
            'X-Auth': token,
            'X-User-Id': userId,
        },
        data: {},
    })
