import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { isEducation, getConstraintLimit } from 'common/utils/permissions'
import { FEATURE_TYPES } from 'common/constants/subscription'

import { THRESHOLD_STATUS } from './constants'

import eyeIcon from './i/eye.svg'
import cloudIcon from './i/cloud.svg'
import fileIcon from './i/file.svg'

const isUnlimited = value => value === -1 || value >= 1000000
const isDanger = (value, total) => (value * 100) / total >= THRESHOLD_STATUS
const isReached = (value, total) => value >= total
const getConstraint = (constraints, featureType) => constraints[featureType] || null

const useUserSubscriptions = constraints => {
    const { t } = useTranslation()
    const [limits, setLimits] = useState([])
    const [dangerTypes, setDangerTypes] = useState([])
    const [reachedTypes, setReachedTypes] = useState([])

    useEffect(() => {
        setDangerTypes(limits.filter(item => !!item.isDanger).map(item => item.type))
        setReachedTypes(limits.filter(item => !!item.isReached).map(item => item.type))
    }, [limits])

    useEffect(() => {
        if (!constraints) return

        const result = []

        // REVIEWS_COUNT
        const reviewCountConstraint = getConstraint(constraints, FEATURE_TYPES.REVIEWS_COUNT)
        if (!!reviewCountConstraint && !isUnlimited(getConstraintLimit(reviewCountConstraint))) {
            result.push({
                label: t('Views per month'),
                icon: eyeIcon,
                type: FEATURE_TYPES.REVIEWS_COUNT,
                value: reviewCountConstraint.spent,
                total: getConstraintLimit(reviewCountConstraint),
                isDanger: isDanger(reviewCountConstraint.spent, getConstraintLimit(reviewCountConstraint)),
                isReached: isReached(reviewCountConstraint.spent, getConstraintLimit(reviewCountConstraint)),
            })
        }

        // STORAGE_SIZE
        const storageSizeConstraint = getConstraint(constraints, FEATURE_TYPES.STORAGE_SIZE)
        if (!!storageSizeConstraint && !isUnlimited(getConstraintLimit(storageSizeConstraint))) {
            result.push({
                label: t('Storage'),
                icon: cloudIcon,
                type: FEATURE_TYPES.STORAGE_SIZE,
                value: storageSizeConstraint.spent,
                total: getConstraintLimit(storageSizeConstraint),
                isDanger: isDanger(storageSizeConstraint.spent, getConstraintLimit(storageSizeConstraint)),
                isReached: isReached(storageSizeConstraint.spent, getConstraintLimit(storageSizeConstraint)),
            })
        }

        // PROJECTS_COUNT
        const projectsCountConstraint = getConstraint(constraints, FEATURE_TYPES.PROJECTS_COUNT)
        if (isEducation() && !!projectsCountConstraint && !isUnlimited(getConstraintLimit(projectsCountConstraint))) {
            result.push({
                label: t('Number of projects'),
                icon: fileIcon,
                type: FEATURE_TYPES.PROJECTS_COUNT,
                value: projectsCountConstraint.spent,
                total: getConstraintLimit(projectsCountConstraint),
                isDanger: isDanger(projectsCountConstraint.spent, getConstraintLimit(projectsCountConstraint)),
                isReached: isReached(projectsCountConstraint.spent, getConstraintLimit(projectsCountConstraint)),
            })
        }

        setLimits(result)
    }, [constraints])

    return { isHasDanger: !!dangerTypes.length, isHasReached: !!reachedTypes.length, dangerTypes, reachedTypes, limits }
}

export default useUserSubscriptions
