import React, { useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { createCollector, createValidator, required } from 'shared/validation'

import Checkbox from 'components/Form/Checkbox/Checkbox'
import Form from 'components/Form/components/Helpers/Form/Form'
import FormCombinedBlocks from 'components/Form/components/Helpers/FormCombinedBlocks/FormCombinedBlocks'
import FormCombinedBlock from 'components/Form/components/Helpers/FormCombinedBlock/FormCombinedBlock'
import FormInputs from 'components/Form/components/Helpers/FormInputs/FormInputs'
import FormInputBlock from 'components/Form/components/Helpers/FormInputBlock/FormInputBlock'
import FormTextInput from 'components/Form/components/Helpers/FormTextInput/FormTextInput'
import FormTextArea from 'components/Form/components/Helpers/FormTextArea/FormTextArea'
import FormButtons from 'components/Form/components/Helpers/FormButtons/FormButtons'
import Toast from 'components/Toast/Toast'
import FillMoreInfoModal from 'components/Modal/FillMoreInfoModal/FillMoreInfoModal'
import { FIELDS } from 'components/Modal/FillMoreInfoModal/constants'
import FormSelect from 'components/Form/components/Helpers/FormSelect/FormSelect'

import { API__CONTENT } from 'api'

import { moveInArray } from 'utils/common'

import UploadImage from '../../../../components/TemplateModal/UploadImage/UploadImage'
import ImagesGallery from '../../../../components/TemplateModal/ImagesGallery/ImagesGallery'

import PriceSelector from './components/PriceSelector/PriceSelector'

import { COST, PRODUCT_TYPE, CURRENCY, PRODUCT_TYPE_DATA, SUBJECTS, GRADE } from './constants'

import styles from './SetUpProduct.module.scss'

const SetUpProduct = ({ userDetails, project, onSuccess, onLoadProjects }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const [isAgreement, setIsAgreement] = useState(true)
    const [subject, setSubject] = useState(null)
    const [grade, setGrade] = useState(null)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [previewImageUrl, setPreviewImageUrl] = useState(null)
    const [gallery, setGallery] = useState([])

    const [cost, setCost] = useState(COST.FREE)
    const [sellAsTemplate, setSellAsTemplate] = useState({
        isActive: true,
        priceValue: PRODUCT_TYPE_DATA[PRODUCT_TYPE.TEMPLATE].defaultPriceValue,
    })
    const [sellAsStaticContent, setSellAsStaticContent] = useState({
        isActive: false,
        priceValue: PRODUCT_TYPE_DATA[PRODUCT_TYPE.STATIC].defaultPriceValue,
    })

    const [errors, setErrors] = useState({})

    const [isOpenedFillMoreInfoModal, setIsOpenedFillMoreInfoModal] = useState(false)

    const onSubmit = async () => {
        setIsLoading(true)
        try {
            const fields = {
                subject,
                grade,
                title: title.trim(),
                description: description.trim(),
            }

            const validationRules = {
                subject: [required()],
                grade: [required()],
                title: [required()],
                description: [required()],
            }
            const isValid = createValidator(validationRules)(fields)
            const errors = createCollector(validationRules)(fields)

            setErrors(errors)
            if (!isValid) return

            if (!previewImageUrl) {
                setIsOpenedFillMoreInfoModal(true)
                return
            }

            const data = {
                authorId: userDetails.id,
                projectId: project.id,
                previewImageUrl,
                title,
                description,
                subject: subject.value,
                grade: grade.value,
                productsSettings: [],
                previewImages: gallery,
            }
            if (cost === COST.PAID) {
                if (sellAsTemplate.isActive) {
                    data.productsSettings.push({
                        productType: PRODUCT_TYPE.TEMPLATE,
                        purchasePrice: sellAsTemplate.priceValue * 100,
                        currency: CURRENCY.USD,
                    })
                }
                if (sellAsStaticContent.isActive) {
                    data.productsSettings.push({
                        productType: PRODUCT_TYPE.STATIC,
                        purchasePrice: sellAsStaticContent.priceValue * 100,
                        currency: CURRENCY.USD,
                    })
                }
            }
            await API__CONTENT.CREATE_PUBLIC_CONTENT(data)

            await onLoadProjects()
            onSuccess()

            Toast('success', {
                message: t('Your product is published successfully'),
            })
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Form>
                <FormInputs>
                    <FormInputBlock>
                        <FormTextInput
                            label={t('Title')}
                            value={title}
                            onUpdate={v => {
                                setTitle(v)
                                setErrors({ ...errors, title: [] })
                            }}
                            maxLength={200}
                            placeholder={t('Short name of your product')}
                            errorMessages={errors.title || []}
                        />
                    </FormInputBlock>
                    <FormCombinedBlocks>
                        <FormCombinedBlock>
                            <FormSelect
                                errorMessages={errors.subject || []}
                                label={t('Subject')}
                                value={subject}
                                onUpdate={val => {
                                    setSubject(val)
                                    setErrors({ ...errors, subject: [] })
                                }}
                                options={SUBJECTS}
                            />
                        </FormCombinedBlock>
                        <FormCombinedBlock>
                            <FormSelect
                                errorMessages={errors.grade || []}
                                label={t('Grade')}
                                value={grade}
                                onUpdate={val => {
                                    setGrade(val)
                                    setErrors({ ...errors, grade: [] })
                                }}
                                options={GRADE}
                            />
                        </FormCombinedBlock>
                    </FormCombinedBlocks>
                    <FormInputBlock>
                        <FormTextArea
                            label={t('Description')}
                            value={description}
                            onUpdate={value => {
                                setDescription(value)
                                setErrors({ ...errors, description: [] })
                            }}
                            maxLength={1200}
                            placeholder={t(
                                'Tell what your project is about and how it will benefit teachers or students',
                            )}
                            errorMessages={errors.description || []}
                        />
                    </FormInputBlock>
                    <FormInputBlock>
                        <UploadImage
                            projectId={project.id}
                            label={t('Preview   ')}
                            url={previewImageUrl}
                            onChange={url => setPreviewImageUrl(url)}
                            description={t(
                                'It can be a screenshot of a project or a specially prepared image\nMinimum size: 800x600 px',
                            )}
                        />
                    </FormInputBlock>
                    <FormInputBlock>
                        <ImagesGallery
                            projectId={project.id}
                            label={t('Gallery')}
                            images={gallery}
                            onAddImage={url => setGallery([...gallery, url])}
                            onChange={(prevUrl, newUrl) =>
                                setGallery(gallery.map(item => (item === prevUrl ? newUrl : item)))
                            }
                            onChangeSort={(from, to) => setGallery(moveInArray(gallery, from, to))}
                            onRemove={removeUrl => setGallery(gallery.filter(item => item !== removeUrl))}
                        />
                    </FormInputBlock>
                    <FormInputBlock>
                        <PriceSelector
                            cost={cost}
                            onChangeTemplateCost={v => setCost(v)}
                            paidList={[
                                {
                                    title: PRODUCT_TYPE_DATA[PRODUCT_TYPE.TEMPLATE].title,
                                    description: PRODUCT_TYPE_DATA[PRODUCT_TYPE.TEMPLATE].description,
                                    isActive: sellAsTemplate.isActive,
                                    isCanDisable: sellAsStaticContent.isActive,
                                    priceValue: sellAsTemplate.priceValue,
                                    prices: PRODUCT_TYPE_DATA[PRODUCT_TYPE.TEMPLATE].prices,
                                    onChange: data => {
                                        setSellAsTemplate({
                                            ...sellAsTemplate,
                                            ...data,
                                        })
                                    },
                                },
                                // {
                                //     title: PRODUCT_TYPE_DATA[PRODUCT_TYPE.STATIC].title,
                                //     description: PRODUCT_TYPE_DATA[PRODUCT_TYPE.STATIC].description,
                                //     isActive: sellAsStaticContent.isActive,
                                //     isCanDisable: sellAsTemplate.isActive,
                                //     priceValue: sellAsStaticContent.priceValue,
                                //     prices: PRODUCT_TYPE_DATA[PRODUCT_TYPE.STATIC].prices,
                                //     onChange: data => {
                                //         setSellAsStaticContent({
                                //             ...sellAsStaticContent,
                                //             ...data,
                                //         })
                                //     },
                                // },
                            ]}
                        />
                    </FormInputBlock>
                </FormInputs>
                <FormButtons className={styles.formButtons}>
                    <Checkbox
                        value={isAgreement}
                        onUpdate={v => setIsAgreement(v)}
                        label={t('I confirm that I have all the necessary rights to the published materials.')}
                        checkboxClassName={styles.checkboxClassName}
                    />
                    <button
                        className={classNames('button', styles.formButton)}
                        data-size="lg"
                        data-variant="primary"
                        disabled={isLoading || !isAgreement}
                        onClick={() => onSubmit()}
                    >
                        {t('Post to the Community')}
                    </button>
                </FormButtons>
            </Form>
            {isOpenedFillMoreInfoModal && (
                <FillMoreInfoModal
                    title={t('Fill in your product information')}
                    subTitle={t('To make your product public, specify in the settings:')}
                    fields={[FIELDS.PREVIEW_IMAGE]}
                    fieldsToFill={[previewImageUrl && FIELDS.PREVIEW_IMAGE]}
                    onClose={() => setIsOpenedFillMoreInfoModal(false)}
                />
            )}
        </>
    )
}

export default SetUpProduct
