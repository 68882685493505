import React from 'react'
import { useTranslation } from 'react-i18next'

import IconMasterCard from 'svg/mc.svg'

const PaymentMethod = ({ lastPayment }) => {
    const { t } = useTranslation()
    return (
        <div className="subscriptions__state__item">
            <span className="subscriptions__state__item-title">{t('Payment method')}</span>
            {lastPayment ? (
                <div className="payment-method">
                    <img className="payment" src={IconMasterCard} />
                    <span className="subscriptions__state__item-value">
                        {lastPayment.paymentMethod} **** {lastPayment.cardLastDigits}
                    </span>
                </div>
            ) : (
                <p className="subscriptions__state__item-value">{t('N/A')}</p>
            )}
        </div>
    )
}

export default PaymentMethod
