import { UTILS__VALIDATOR__is_value } from 'utils/validator.js'

import { MAIN_SERVER } from './instance'

export const GET_ORGANIZATION_LIST = async () =>
    MAIN_SERVER({
        method: 'get',
        url: `/organizations`,
    })

export const UPDATE_ORGANIZATION = async (organizationId, info) => {
    const data = {}

    if (UTILS__VALIDATOR__is_value(info.logoURL)) {
        data.logoURL = info.logoURL
    }
    if (UTILS__VALIDATOR__is_value(info.name)) {
        data.name = info.name
    }

    return MAIN_SERVER({
        method: 'patch',
        url: `/organizations/${organizationId}`,
        data,
    })
}

export const GET_ORGANIZATION_MEMBER_LIST = async (organizationId, isApproved) =>
    MAIN_SERVER({
        method: 'get',
        url: `/organizations/${organizationId}/members`,
        params: {
            isApproved,
        },
    })

export const INVITE_ORGANIZATION_MEMBERS = async (organizationId, emails) =>
    MAIN_SERVER({
        method: 'post',
        url: `/organizations/${organizationId}/members`,
        data: {
            emails,
        },
    })

export const REMOVE_ORGANIZATION_MEMBER = async (organizationId, userId) =>
    MAIN_SERVER({
        method: 'delete',
        url: `/organizations/${organizationId}/members/${userId}`,
    })

export const APPROVE_ORGANIZATION_INVITE = async (organizationId, userId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/organizations/${organizationId}/members/${userId}/approve`,
    })

export const DECLINE_ORGANIZATION_INVITE = async (organizationId, userId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/organizations/${organizationId}/members/${userId}/decline`,
    })

export const LEAVE_FROM_ORGANIZATION = async (organizationId, userId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/organizations/${organizationId}/members/${userId}/leave`,
    })

export const GET_ORGANIZATION_SUBSCRIPTION = async organizationId =>
    MAIN_SERVER({
        method: 'get',
        url: `/organizations/${organizationId}/selected-subscription`,
    })

export const SET_ORGANIZATION_LOGO = async (organizationId, contentType, bytes) =>
    MAIN_SERVER({
        method: 'post',
        url: `/organizations/${organizationId}/logo`,
        headers: {
            'Content-Type': `${contentType}`,
        },
        params: {
            fileLength: bytes.length || bytes.byteLength,
        },
        data: bytes,
        timeout: 60000,
    })
