import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Modal from 'components/Modal/Modal'
import Toast from 'components/Toast/Toast'
import LoaderEllipsis from 'components/LoaderEllipsis/LoaderEllipsis'

import { getCurrencySymbol } from 'utils/subscription'
import { getFullUrlToSsr } from 'utils/router'

import AnalyticsMonitor from 'common/services/AnalyticsMonitor'

import { PRODUCT_LIST } from 'common/constants/ProductConstants'

import { API__PAYMENTS, API__PRODUCTS } from 'api'

import ChooseCheckoutModal from './components/ChooseCheckoutModal/ChooseCheckoutModal'

import styles from './UpgradeProjectTariff.module.scss'

const UpgradeProjectTariff = ({ project, onClose }) => {
    const { t } = useTranslation()

    const [cards, setCards] = useState([])
    const [chooseCheckoutModal, setChooseCheckoutModal] = useState(null)

    const { paymentCurrency } = useSelector(state => state)

    useEffect(() => {
        API__PRODUCTS.GET_ONE_TIME_PRODUCTS(paymentCurrency).then(products => {
            products.sort((a, b) => a.purchasePrice - b.purchasePrice)
            setCards(products)
        })
    }, [])

    const onUpgrade = async isBusinessPayment => {
        try {
            const url = await API__PAYMENTS.GET_PAYMENT_LINK({
                productCode: chooseCheckoutModal.productCode,
                projectId: project.id,
                isBusinessPayment,
            })
            if (url) {
                window.location.href = String(url)
                setChooseCheckoutModal(null)
            }
        } catch (err) {
            console.error(err)
            Toast('error', {})
        }
    }

    return (
        <>
            <Modal isShowCloseIcon closeOnOverlayClick={false} closeOnEsc={false} onClose={onClose}>
                <div className={styles.upgradeProjectTariff}>
                    <h2 className={styles.title}>{t('Premium subscription for one project')}</h2>
                    <p className={styles.description}>
                        {t('You can purchase a subscription for one project for a month or more.')}
                    </p>
                    <p className={styles.description}>{t('Suitable for events and one-time promotions.')}</p>
                    {!!cards.length ? (
                        <ul className={styles.productList}>
                            {cards.map(card => (
                                <li
                                    key={card.id}
                                    className={styles.cardItem}
                                    style={{
                                        width: `${100 / cards.length}%`,
                                    }}
                                >
                                    <div className={styles.cardBox}>
                                        <div className={styles.cardInfo}>
                                            <div className={styles.planName}>{card.name}</div>
                                            <div className={styles.price}>
                                                <div className={styles.priceCurrency}>
                                                    {getCurrencySymbol(card.currency)}
                                                </div>
                                                <div
                                                    className={classNames(styles.priceValue, {
                                                        [styles.isCompactPriceValue]:
                                                            String(card.purchasePrice).length > 3,
                                                    })}
                                                >
                                                    {card.purchasePrice}
                                                </div>
                                                <div className={styles.cycle}>
                                                    <div className={styles.cycleValue}>/{t('month')}</div>
                                                </div>
                                            </div>
                                            <div className={styles.cardDescription}>
                                                {card.productCode === PRODUCT_LIST.OneTimeWhitelabel &&
                                                    t('Your branding and up to 10,000 leads per month.')}
                                                {card.productCode === PRODUCT_LIST.OneTimeGamification &&
                                                    t(
                                                        'Turn on the rating of players and arrange a prize draw. Suitable for small events',
                                                    )}
                                                {card.productCode === PRODUCT_LIST.OneTimeEvent &&
                                                    t('Advanced plan for medium and large events.')}
                                            </div>
                                            <div className={styles.corresponds}>
                                                {card.productCode === PRODUCT_LIST.OneTimeWhitelabel && (
                                                    <Trans
                                                        i18nKey="Corresponds to the <a>{{tariff}}</a> plan"
                                                        values={{
                                                            tariff: 'PRO',
                                                        }}
                                                        components={{
                                                            a: (
                                                                <a
                                                                    href={getFullUrlToSsr(
                                                                        '/pricing/business?group=business',
                                                                    )}
                                                                    target="_blank"
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                {card.productCode === PRODUCT_LIST.OneTimeGamification && (
                                                    <Trans
                                                        i18nKey="Corresponds to the <a>{{tariff}}</a> plan"
                                                        values={{
                                                            tariff: 'PRO+',
                                                        }}
                                                        components={{
                                                            a: (
                                                                <a
                                                                    href={getFullUrlToSsr(
                                                                        '/pricing/business?group=business',
                                                                    )}
                                                                    target="_blank"
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                {card.productCode === PRODUCT_LIST.OneTimeEvent && (
                                                    <Trans
                                                        i18nKey="Corresponds to the <a>{{tariff}}</a> plan"
                                                        values={{
                                                            tariff: 'Teams',
                                                        }}
                                                        components={{
                                                            a: (
                                                                <a
                                                                    href={getFullUrlToSsr(
                                                                        '/pricing/business?group=business',
                                                                    )}
                                                                    target="_blank"
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <button
                                            className={classNames(styles.upgradeButton, 'button')}
                                            data-size="lg"
                                            data-variant="primary"
                                            onClick={() => {
                                                AnalyticsMonitor.track('sendBuyMethodChoosingToAnalytics')
                                                setChooseCheckoutModal(card)
                                            }}
                                        >
                                            {t('Upgrade')}
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className={styles.loader}>
                            <LoaderEllipsis />
                        </div>
                    )}
                </div>
            </Modal>
            {!!chooseCheckoutModal && (
                <ChooseCheckoutModal onClose={() => setChooseCheckoutModal(null)} onCheckout={onUpgrade} />
            )}
        </>
    )
}
export default UpgradeProjectTariff
