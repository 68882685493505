import BLOCK from 'common/constants/BlockTypes/BlocksEnum.js'
import { CONDITIONS, hasPermission, isEducation } from 'common/utils/permissions.js'
import storage from 'common/utils/storage.js'

export const DONT_SHOW_AGAIN_PROMO = 'DONT_SHOW_AGAIN_PROMO'
export const DONT_SHOW_AGAIN_PROMO_TIME = 'DONT_SHOW_AGAIN_PROMO_TIME'
const DAY_TIME = 86400000 // 1 day

export const isShowGamificationPromo = projectBocks => {
    const dontShowAgainPromo = storage.getValue(DONT_SHOW_AGAIN_PROMO)
    const dontShowAgainPromoTime = storage.getValue(DONT_SHOW_AGAIN_PROMO_TIME)
    if (
        isEducation() &&
        !hasPermission(CONDITIONS.CAN_USE_GAMIFICATION) &&
        (!dontShowAgainPromo || new Date().getTime() > dontShowAgainPromoTime)
    ) {
        return projectBocks.some(el => el.t === BLOCK.memoryCards)
    }
    return false
}

export const delayGamificationPromo = () => {
    storage.setValue(DONT_SHOW_AGAIN_PROMO, 'true')
    storage.setValue(DONT_SHOW_AGAIN_PROMO_TIME, String(new Date().getTime() + DAY_TIME * 7))
}
