export const REMIX_SUPPORTED_FONTS = [
    'Roboto',
    'Roboto Condensed',
    'Open Sans',
    'Open Sans Condensed',
    'Lato',
    'Montserrat',
    'Oswald',
    'Merriweather',
    'Ubuntu',
    'Lobster',
    'Pacifico',
    'Vollkorn',
    'Cuprum',
    'Alegreya Sans',
    'Russo One',
    'Playfair Display',
    'Playfair Display SC',
    'Alice',
    'Press Start 2P',
    'Bad Script',
    'Yeseva One',
    'Marmelad',
    'Rubik Mono One',
    'Raleway',
    'Roboto Slab',
    'Lora',
    'Seymour One',
    'Cormorant SC',
    'Literata',
    'Spectral',
    'Alegreya',
    'EB Garamond',
    'Bitter',
    'PT Serif',
    'Noto Sans',
    'Manrope',
    'Unbounded',
]

export const getRemixFontsList = (asSelectOptions = false) => {
    if (asSelectOptions) return REMIX_SUPPORTED_FONTS.sort().map(font => ({ value: font, label: font }))
    return REMIX_SUPPORTED_FONTS.sort()
}
