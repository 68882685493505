import store from 'store'

import { MAIN_SERVER } from './instance'

const UPLOAD_IMAGE_TIME = 100000

export const UPLOAD_IMAGE = ({ fileName, contentType, bytes, uploadRequestParams = {} }) => {
    const {
        organizations: { selectedOrganizationId },
    } = store.getState()

    return MAIN_SERVER({
        method: 'post',
        url: `/media/images`,
        headers: {
            'Content-Type': `${contentType}`,
        },
        params: {
            fileName,
            hidden: false,
            resizable: true,
            fileLength: bytes.length || bytes.byteLength,
            organizationId: selectedOrganizationId,
            ...uploadRequestParams,
        },
        data: bytes,
        timeout: UPLOAD_IMAGE_TIME,
    })
}

export const GET_IMAGES = (organizationId, size, page) =>
    MAIN_SERVER({
        method: 'get',
        url: `/media/images`,
        params: {
            size,
            page,
            sort: 'created,desc',
            organizationId,
        },
    })

export const GET_UNSPLASH_IMAGES = (size, page, search) => {
    const {
        user_details: { id },
    } = store.getState()

    const queryType = search ? 'search' : 'photos'

    return MAIN_SERVER({
        method: 'get',
        url: `users/${id}/media/external/unsplash/${queryType}`,
        params: {
            size,
            page,
            query: search,
            userId: id,
        },
    })
}

export const GET_PIXABAY_IMAGES = (size, page, search) => {
    const {
        user_details: { id },
    } = store.getState()

    return MAIN_SERVER({
        method: 'get',
        url: `users/${id}/media/external/pixabay/photos`,
        params: {
            size,
            page,
            query: search,
            userId: id,
        },
    })
}

export const SAVE_UNSPLASH_IMAGE = (imageId, user, urls) => {
    const {
        user_details: { id },
        organizations: { selectedOrganizationId },
    } = store.getState()

    return MAIN_SERVER({
        method: 'post',
        url: `users/${id}/media/external/unsplash/images`,
        data: {
            organizationId: selectedOrganizationId,
            imageId,
            user,
            urls,
        },
        timeout: UPLOAD_IMAGE_TIME,
    })
}
export const SAVE_PIXABAY_IMAGE = async ({
    imageId,
    imageUrl,
    isResizableInMainResolution = false,
    isCompressibleInMainResolution = false,
}) => {
    const {
        organizations: { selectedOrganizationId },
    } = store.getState()

    return await MAIN_SERVER({
        method: 'post',
        url: `/media/images`,
        data: {
            isResizable: true,
            isHidden: false,
            organizationId: selectedOrganizationId,
            fileName: imageId,
            url: imageUrl,
            isResizableInMainResolution,
            isCompressibleInMainResolution,
        },
        timeout: UPLOAD_IMAGE_TIME,
    })
}

export const DELETE_IMAGE = id =>
    MAIN_SERVER({
        method: 'delete',
        url: `/media/images/${id}`,
    })

export const UPLOAD_AUDIO = ({ fileName, contentType, bytes }) => {
    const {
        organizations: { selectedOrganizationId },
    } = store.getState()

    return MAIN_SERVER({
        method: 'post',
        url: `/media/audios`,
        headers: {
            'Content-Type': `${contentType}`,
        },
        params: {
            fileName,
            hidden: false,
            fileLength: bytes.length || bytes.byteLength,
            organizationId: selectedOrganizationId,
        },
        data: bytes,
        timeout: UPLOAD_IMAGE_TIME,
    })
}

export const GET_AUDIO_LIST = (organizationId, size, page) =>
    MAIN_SERVER({
        method: 'get',
        url: `/media/audios`,
        params: {
            size,
            page,
            sort: 'created,desc',
            organizationId,
        },
    })

export const DELETE_AUDIO = id =>
    MAIN_SERVER({
        method: 'delete',
        url: `/media/audios/${id}`,
    })

export const GET_PUBLIC_AUDIO_FOLDERS = () =>
    MAIN_SERVER({
        method: 'get',
        url: '/folders/public',
        params: {
            category: 'AUDIO',
            size: 100,
            page: 0,
            sort: 'name,asc',
        },
    })

export const GET_PUBLIC_AUDIO = folderId =>
    MAIN_SERVER({
        method: 'get',
        url: `/media/audios/public`,
        params: {
            folderId,
            size: 100,
            page: 0,
            sort: 'name,asc',
        },
    })

export const UPLOAD_PUBLIC_TEMPLATE_PREVIEW_IMAGE = (contentType, bytes, projectId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/media/user-templates/upload-image-preview`,
        headers: {
            'Content-Type': `${contentType}`,
        },
        params: {
            fileLength: bytes.length || bytes.byteLength,
            projectId,
        },
        data: bytes,
        timeout: UPLOAD_IMAGE_TIME,
    })

export const UPLOAD_PUBLIC_TEMPLATE_GALLERY_IMAGE = (contentType, bytes, projectId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/media/user-templates/upload-image-gallery`,
        headers: {
            'Content-Type': `${contentType}`,
        },
        params: {
            fileLength: bytes.length || bytes.byteLength,
            projectId,
        },
        data: bytes,
        timeout: UPLOAD_IMAGE_TIME,
    })
