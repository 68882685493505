import React, { useRef, useState, useEffect } from 'react'
import Cropper from 'react-cropper'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { getFileDataURL } from 'utils/file'

import Modal from 'components/Modal/Modal'
import LoaderEllipsis from 'components/LoaderEllipsis/LoaderEllipsis'
import { MAX_IMAGE_FILE_SIZE, UPLOAD_STATUS } from 'components/AssetManager/utils/constants'

import { ASSET_MANAGER__ADD_UPLOAD_MEDIA, ASSET_MANAGER__ADD_UPLOAD_MEDIA_INFO } from 'store/actions'

import { mapImageToAssetFormat } from '../../AssetManager/utils/utils'

import { SHAPES } from './constants'

import Rectangle from './svg/Rectangle'
import Circle from './svg/Circle'
import Square from './svg/Square'

import { getRoundedCanvas, getFileType } from './utils'

import styles from './ImageCropper.module.scss'

const ImageCropper = ({
    url,
    file,
    data,
    onCancel,
    onPlace,
    setIsLoading,
    isResizableInMainResolution,
    isCompressibleInMainResolution,
}) => {
    const { t } = useTranslation()

    const [src, setSrc] = useState(null)
    const [shape, setShape] = useState(null)

    const cropperRef = useRef(null)

    const uploadMedia = Object.entries(useSelector(state => state.assetManager.uploadMedia))

    const dispatch = useDispatch()

    useEffect(() => {
        if (url) {
            setSrc(url)
            return
        }

        if (data) {
            setSrc(data)
            setShape(SHAPES.RECTANGLE)
        }
        if (file) {
            getFileDataURL(file)
                .then(_data => {
                    setSrc(_data)
                    setShape(SHAPES.RECTANGLE)
                })
                .catch(err => console.error(err))
        }
    }, [])

    useEffect(() => {
        const cropper = cropperRef.current?.cropper
        if (!cropper) return
        if ([SHAPES.CIRCLE, SHAPES.SQUARE].includes(shape)) cropper.setAspectRatio(1)
        else cropper.setAspectRatio(NaN)
    }, [shape])

    const checkAndUpload = async (blob, name) => {
        try {
            setIsLoading(true)
            if (blob.size >= MAX_IMAGE_FILE_SIZE) {
                await dispatch(
                    ASSET_MANAGER__ADD_UPLOAD_MEDIA_INFO({
                        total: blob.size,
                        name,
                        status: UPLOAD_STATUS.error,
                        message: t('File size is too large'),
                    }),
                )
                return
            }

            const response = await dispatch(
                ASSET_MANAGER__ADD_UPLOAD_MEDIA({
                    file: blob,
                    name,
                    uploadRequestParams: { isResizableInMainResolution, isCompressibleInMainResolution },
                }),
            )

            return mapImageToAssetFormat(response)
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    const onCrop = () => {
        const cropper = cropperRef.current?.cropper
        if (!cropper) return

        let fileName = new Date().toUTCString()
        let canvas = cropper.getCroppedCanvas()

        if (file) fileName = file.name.substring(0, file.name.lastIndexOf('.')) || file.name

        if (shape === SHAPES.CIRCLE) canvas = getRoundedCanvas(canvas)

        const fileType = getFileType(file, shape)
        canvas.toBlob(
            async blob => {
                const image = await checkAndUpload(blob, `${fileName}.${fileType.split('/')[1]}`)
                onPlace(image)
            },
            fileType,
            0.85,
        )
    }

    return (
        <Modal onClose={onCancel} className={classNames({ [styles.isHiddenModal]: !!uploadMedia.length })}>
            <div className={styles.imageCropper}>
                {!!src ? (
                    <>
                        <Cropper
                            className={shape === SHAPES.CIRCLE ? 'cropper-circle' : ''}
                            ref={cropperRef}
                            src={src}
                            style={{ height: 400, width: '100%' }}
                            viewMode={1}
                            aspectRatio={NaN}
                            minCropBoxHeight={40}
                            minCropBoxWidth={40}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            guides={true}
                            zoomable={false}
                        />
                        <div className={styles.bottomBar}>
                            <div className={styles.shapeSelector}>
                                <p className={styles.shapeText}>{t('Shape')}</p>
                                <ul className={styles.shapeList}>
                                    <li
                                        title={t('Rectangle')}
                                        onClick={() => setShape(SHAPES.RECTANGLE)}
                                        className={classNames(styles.shape, {
                                            [styles.isActiveShape]: shape === SHAPES.RECTANGLE,
                                        })}
                                    >
                                        <Rectangle isActive={shape === SHAPES.RECTANGLE} />
                                    </li>
                                    <li
                                        title={t('Circle')}
                                        onClick={() => setShape(SHAPES.CIRCLE)}
                                        className={classNames(styles.shape, {
                                            [styles.isActiveShape]: shape === SHAPES.CIRCLE,
                                        })}
                                    >
                                        <Circle isActive={shape === SHAPES.CIRCLE} />
                                    </li>
                                    <li
                                        title={t('Square')}
                                        onClick={() => setShape(SHAPES.SQUARE)}
                                        className={classNames(styles.shape, {
                                            [styles.isActiveShape]: shape === SHAPES.SQUARE,
                                        })}
                                    >
                                        <Square isActive={shape === SHAPES.SQUARE} />
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.actions}>
                                <button className="button" data-size="md" onClick={onCancel} data-variant="secondary">
                                    {t('Cancel')}
                                </button>
                                <button className="button" data-size="md" onClick={onCrop} data-variant="primary">
                                    {t('Place image')}
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <LoaderEllipsis />
                )}
            </div>
        </Modal>
    )
}

export default ImageCropper
