import React from 'react'
import { withTranslation } from 'react-i18next'

import { CONDITIONS, hasPermission, isAppSumoOrBusinessUser } from 'common/utils/permissions'

import { isEuVersion } from 'common/constants/env'

import UpgradeDialog from 'components/Modal/UpgradeDialog/UpgradeDialog'
import Toast from 'components/Toast/Toast'

import { API__PROJECTS } from 'api'

import SharingSettingsModal from './components/Modal/SharingSettingsModal/SharingSettingsModal'
import CustomProjectLinkModal from './components/Modal/CustomProjectLinkModal/CustomProjectLinkModal'

import iconEdit from './i/edit.svg'

import './SharingOptions.scss'

class SharingOptions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpenSharingModal: false,
            isOpenUpgradeModal: false,
            isOpenCustomProjectUrlModal: false,
            customProjectUrlModal: {
                value: '',
                status: '',
                canCheck: false,
                canSave: false,
            },
            customProjectUrl: this.props.project.customUrl,
        }
    }

    openCustomProjectUrlModal = async () => {
        const { customProjectUrl } = this.state

        if (hasPermission(CONDITIONS.CAN_USE_CUSTOM_PROJECT_ID)) {
            await this.setState(prevState => ({
                isOpenCustomProjectUrlModal: true,
                customProjectUrlModal: {
                    ...prevState.customProjectUrlModal,
                    value: customProjectUrl !== null ? customProjectUrl : '',
                },
            }))
        } else this.setState({ isOpenUpgradeModal: true })
    }

    changeCustomProjectUrlModal = async value => {
        const { customProjectUrl } = this.state

        await this.setState(prevState => ({
            customProjectUrlModal: {
                ...prevState.customProjectUrlModal,
                value,
                status: '',
                canCheck: value.length,
                canSave: value.length && value !== customProjectUrl,
            },
        }))
    }

    checkCustomProjectUrlModal = async () => {
        this.setState(prevState => ({
            customProjectUrlModal: {
                ...prevState.customProjectUrlModal,
                status: '',
            },
        }))

        const { customProjectUrlModal } = this.state
        API__PROJECTS.CHECK_CUSTOM_URL(customProjectUrlModal.value)
            .then(response_1 => {
                const checkStatus = response_1.status
                // possible values: 'FREE' and 'ALREADY_TAKEN'
                if (checkStatus)
                    this.setState(prevState => ({
                        customProjectUrlModal: {
                            ...prevState.customProjectUrlModal,
                            status: checkStatus.toLowerCase(),
                        },
                    }))
            })
            .catch(err => {
                if (err.response && err.response.status === 422) {
                    this.setState(prevState => ({
                        customProjectUrlModal: {
                            ...prevState.customProjectUrlModal,
                            status: 'invalid',
                        },
                    }))
                    return
                }
                Toast('error', {})
                throw err
            })
    }

    saveCustomProjectUrl = async () => {
        try {
            const { project, methods, i18n } = this.props
            const { customProjectUrlModal } = this.state

            await API__PROJECTS.SET_CUSTOM_URL(project.id, customProjectUrlModal.value)

            await methods.changeProject('customUrl', customProjectUrlModal.value)
            Toast('success', {
                title: i18n.t('Saved'),
                time: 2000,
            })
            this.setState({
                customProjectUrl: customProjectUrlModal.value,
                isOpenCustomProjectUrlModal: false,
            })
        } catch (err) {
            console.error(err)

            if (err.response) {
                const {
                    response: { status },
                } = err
                if (status === 400) {
                    this.setState(prevState => ({
                        customProjectUrlModal: {
                            ...prevState.customProjectUrlModal,
                            status: 'already_taken',
                        },
                    }))
                    return
                }
                if (status === 422) {
                    this.setState(prevState => ({
                        customProjectUrlModal: {
                            ...prevState.customProjectUrlModal,
                            status: 'invalid',
                        },
                    }))
                    return
                }
            }

            Toast('error', {})
        }
    }

    toggleCustomProjectUrl = async () => {
        try {
            const { customProjectUrl } = this.state
            if (customProjectUrl) {
                const { project, methods } = this.props
                await API__PROJECTS.SET_CUSTOM_URL(project.id, null)

                this.setState({
                    customProjectUrl: null,
                    isOpenCustomProjectUrlModal: false,
                })

                await methods.changeProject('customUrl', null)
            } else {
                this.openCustomProjectUrlModal()
            }
        } catch (err) {
            console.error(err)
            Toast('error', {})
        }
    }

    onUpgradeClick = async () => {
        const { methods, history } = this.props
        await methods.save()
        history.push('/billing-and-payments?tab=subscription')
    }

    render() {
        const { i18n, project, methods } = this.props
        const {
            customProjectUrlModal,
            customProjectUrl,
            isOpenUpgradeModal,
            isOpenSharingModal,
            isOpenCustomProjectUrlModal,
        } = this.state

        return (
            <div className="review">
                <h1>{i18n.t('Here you can customize the display of the post for social networks')}</h1>

                <div className="wrap-window">
                    <div className="box box--sharing">
                        <h3>{i18n.t('Sharing options ')}</h3>
                        <div className="inner-box">
                            <div
                                className={`preview-img`}
                                style={{
                                    backgroundImage: `url(${project.imageUrl})`,
                                }}
                            />
                            <div className="text">
                                <div className="title">{project.title}</div>
                                <div className="description">{project.description}</div>
                            </div>
                        </div>
                        <div className="btns">
                            <button
                                className="button"
                                data-size="md"
                                data-variant="secondary"
                                onClick={() => this.setState({ isOpenSharingModal: true })}
                            >
                                {i18n.t('Edit')}
                            </button>
                        </div>
                    </div>
                    {isAppSumoOrBusinessUser() && !isEuVersion && (
                        <div className="box box--custom-url">
                            <div className="head">
                                <p>{i18n.t('Custom link id for your project')}</p>
                                <div
                                    className={`switcher${customProjectUrl ? ' is-checked' : ''}`}
                                    onClick={() => this.toggleCustomProjectUrl()}
                                />
                            </div>
                            <div className="link">
                                <div className="link--domain">{`${process.env.REACT_APP_BACKEND_URL.replace(
                                    'api.',
                                    '',
                                )}/projects/`}</div>
                                <div className="project_id">
                                    {customProjectUrl ? (
                                        <span>{customProjectUrl}</span>
                                    ) : (
                                        <span className="ph">best-project-name</span>
                                    )}
                                </div>
                                {customProjectUrl && (
                                    <span className="edit-btn" onClick={() => this.openCustomProjectUrlModal()}>
                                        <img src={iconEdit} alt="" />
                                    </span>
                                )}
                            </div>
                            <p className="description">
                                {i18n.t(
                                    'Setting the link id is optional and allows you to set a more beautiful name for your project.',
                                )}
                            </p>
                        </div>
                    )}
                </div>

                {isOpenUpgradeModal && (
                    <UpgradeDialog
                        onClose={() => this.setState({ isOpenUpgradeModal: false })}
                        onUpgrade={() => this.onUpgradeClick()}
                    />
                )}

                {isOpenCustomProjectUrlModal && (
                    <CustomProjectLinkModal
                        onClose={() => this.setState({ isOpenCustomProjectUrlModal: false })}
                        onChange={value => this.changeCustomProjectUrlModal(value)}
                        onCheck={() => this.checkCustomProjectUrlModal()}
                        onSave={() => this.saveCustomProjectUrl()}
                        data={{
                            value: customProjectUrlModal.value,
                            status: customProjectUrlModal.status,
                            canCheck: customProjectUrlModal.canCheck,
                            canSave: customProjectUrlModal.canSave,
                        }}
                    />
                )}

                {isOpenSharingModal && (
                    <SharingSettingsModal
                        project={project}
                        onClose={() => this.setState({ isOpenSharingModal: false })}
                        onSave={() => methods.save()}
                        onChangeProject={methods.changeProject}
                    />
                )}
            </div>
        )
    }
}

export default withTranslation('translations')(SharingOptions)
