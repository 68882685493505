import React from 'react'
import { useTranslation } from 'react-i18next'

import AddButton from '../AddButton/AddButton'

import styles from './Header.module.scss'

const Header = ({ itemsCount, onAdd }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.header}>
            <p className={styles.title}>{t('Words and clues')}</p>
            <AddButton itemsCount={itemsCount} onAdd={onAdd} />
        </div>
    )
}

export default Header
