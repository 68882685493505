import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import { API__ACTIONS, API__ANALYTICS } from 'api/index.js'
import GAMIFICATION_ACTION_TYPES from 'common/constants/GamificationActionTypes.js'
import { isOwner } from 'common/utils/permissions.js'
import store from 'store'

import DateRangePanel from '../../../../components/DateRangePanel/DateRangePanel'

import { mapGameRatingsToDateRangePanel, mapRatingToDateRange } from './utils.js'

const InteractiveBlockWrapper = ({
    title,
    setDateRange,
    setPeriodInterval,
    children,
    projectId,
    blockId,
    blockTypeId,
    authorId,
}) => {
    const { user_details } = store.getState()
    const [ratingList, setRatingList] = useState([])
    const [ratingPeriod, setRatingPeriod] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const getRatings = () => {
        API__ANALYTICS.GET_PROJECT_STATISTICS_BY_ACTION_TYPE({
            projectId,
            blockId,
            actionName: GAMIFICATION_ACTION_TYPES[blockTypeId].reset,
        }).then(ratingsData => {
            const latestRating = isEmpty(ratingsData) ? null : mapRatingToDateRange(ratingsData[ratingsData.length - 1])
            setRatingPeriod(latestRating)
            setRatingList(mapGameRatingsToDateRangePanel(ratingsData))
        })
    }

    useEffect(() => {
        setRatingList([])
        setRatingPeriod(null)
        if (GAMIFICATION_ACTION_TYPES[blockTypeId]) getRatings()
    }, [projectId, blockId])

    const onStartNewRating = async () => {
        setIsLoading(true)
        try {
            await API__ACTIONS.CREATE_ACTION({
                projectId: projectId,
                blockId: blockId,
                actionName: GAMIFICATION_ACTION_TYPES[blockTypeId].reset,
            })
            getRatings()
        } finally {
            setIsLoading(false)
        }
    }

    const isBlockHaveRating = ratingList.length > 0

    const isCanStartNewRating = GAMIFICATION_ACTION_TYPES[blockTypeId] && (isOwner() || authorId === user_details.id)

    return (
        <>
            <h1>{title}</h1>
            <DateRangePanel
                isBlockHaveRating={isBlockHaveRating}
                isCanStartNewRating={isCanStartNewRating}
                ratingList={ratingList}
                ratingPeriod={ratingPeriod}
                onChangeRange={(v, _periodInterval) => {
                    setDateRange(v)
                    setPeriodInterval(_periodInterval.value)
                }}
                onStartNewRating={onStartNewRating}
                isLoading={isLoading}
            />
            {children}
        </>
    )
}

export default InteractiveBlockWrapper
