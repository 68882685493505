import React from 'react'
import Pagination from '../../Pagination/Pagination'

import './DataGrid.scss'

const DataGrid = ({ columns, pagination, isLoading, emptyText = 'No records yet', onPageChange }) => {
    const getValue = (column, row) => (column.getValue ? column.getValue(row[column.field]) : row[column.field])
    const getTitle = (column, row) => (column.getTitle ? column.getTitle(row[column.field]) : row[column.field])

    return (
        <div className="data-grid">
            {!pagination.content.length ? (
                <div className="data-grid__empty">{emptyText}</div>
            ) : (
                <>
                    <ul className="data-grid__header">
                        {columns.map((column, columnIndex) => (
                            <li className="data-grid__header__column" key={columnIndex} style={column.styles}>
                                {column.headerName}
                            </li>
                        ))}
                    </ul>
                    {pagination.content.map((row, rowIndex) => (
                        <ul key={rowIndex} className="data-grid__content">
                            {columns.map((column, columnIndex) => (
                                <li
                                    key={columnIndex}
                                    className="data-grid__content__column"
                                    style={column.styles}
                                    title={getTitle(column, row)}
                                >
                                    {getValue(column, row)}
                                </li>
                            ))}
                        </ul>
                    ))}
                </>
            )}

            <Pagination
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                currentPage={pagination.pageable.pageNumber}
                totalPages={pagination.totalPages}
                isLoading={isLoading}
                onChange={page => {
                    onPageChange(page)
                }}
            />
        </div>
    )
}

export default DataGrid
