import React from 'react'
import { withTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

import iconUnlock from './i/unlocked.svg'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Footer from 'components/Modal/components/Footer/Footer'
import Toast from 'components/Toast/Toast'

import './PrivacySettingsModal.scss'

class PrivacySettingsModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSaving: false,
            inputFocused: false,
            tmpValue: '',
            list: [],
        }
        this.baseState = this.state

        this.inputRef = React.createRef()
    }

    componentDidMount() {
        const { passwordList } = this.props.data
        this.setState({ list: passwordList })
        this.inputRef.current.focus()
    }

    onSave = async () => {
        try {
            const { onClose, onUpdate } = this.props
            const { blockId, fieldName } = this.props.data
            const { list } = this.state

            onUpdate({
                [blockId]: {
                    data: { [fieldName]: list },
                },
            })

            onClose()

            Toast('success', { time: 2000 })
        } catch (err) {
            console.error(err)
            Toast('error', {})
        }
    }

    changeTmpValue = value => {
        const splitValues = value.split(/[ ,]+/)
        if (splitValues.length > 1) {
            this.addItemsToList(splitValues.filter(el => el.trim().length))
            this.setState({ tmpValue: '' })
            return
        }
        this.setState({ tmpValue: value })
    }

    addItemsToList = (arr = []) => {
        this.setState(prevState => ({
            list: [...new Set([...prevState.list, ...arr])],
        }))
    }
    removeItemFromListByIndex = i => {
        const { list } = this.state
        const _list = cloneDeep(list)
        _list.splice(i, 1)
        this.setState({
            list: _list,
        })
    }

    add = () => {
        const { tmpValue } = this.state
        if (tmpValue.trim().length) {
            this.addItemsToList([tmpValue])
            this.setState({
                tmpValue: '',
            })
        }
    }
    clear = () => {
        this.setState({
            list: [],
        })
    }

    render() {
        const { isSaving, tmpValue, list } = this.state
        const { i18n, onClose } = this.props

        return (
            <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={onClose}>
                <Header title={i18n.t('Privacy')} onClose={onClose} />

                <div className="privacy-settings-modal">
                    <ScrollableBlock>
                        <div className="scrollbar-inner">
                            <h2 className="privacy-settings-modal__title">
                                {i18n.t(
                                    'As a password, you can use a keyword, email address or a set of numbers.\nTo add multiple passwords, separate them with a comma or space.',
                                )}
                            </h2>
                            <div className="privacy-settings-modal__workplace">
                                <div className="privacy-settings-modal__adding-box">
                                    <div className="privacy-settings-modal__adding-box__title">
                                        {i18n.t('Add password')}
                                    </div>
                                    <div className="privacy-settings-modal__adding-box__block">
                                        <input
                                            ref={this.inputRef}
                                            onKeyPress={evt => evt.key !== 'Enter' || this.add()}
                                            type="text"
                                            value={tmpValue}
                                            onChange={evt => this.changeTmpValue(evt.target.value)}
                                        />
                                        <button
                                            className="button"
                                            data-variant="primary"
                                            disabled={!tmpValue.length}
                                            data-size="lg"
                                            onClick={() => this.add()}
                                        >
                                            {i18n.t('Add')}
                                        </button>
                                    </div>
                                </div>
                                {list.length ? (
                                    <div className="privacy-settings-modal__table">
                                        <div className="privacy-settings-modal__table__head">
                                            <p>
                                                {i18n.t('Active passwords')} <span>({list.length})</span>
                                            </p>
                                            <div onClick={() => this.clear()}>{i18n.t('Clear All')}</div>
                                        </div>
                                        <ul className="privacy-settings-modal__table__body">
                                            {list.map((item, i) => {
                                                return (
                                                    <li key={i} className="privacy-settings-modal__table__item">
                                                        {item}
                                                        <span onClick={() => this.removeItemFromListByIndex(i)}></span>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                ) : (
                                    <div className="privacy-settings-modal__empty-block">
                                        <img src={iconUnlock} alt="unlock" />
                                        <p>{i18n.t('This project is not\npassword protected')}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </ScrollableBlock>
                </div>

                <Footer
                    actionText={i18n.t('Save')}
                    cancelText={i18n.t('Cancel')}
                    isActionDisabled={isSaving}
                    onAction={this.onSave}
                    onClose={onClose}
                />
            </Modal>
        )
    }
}

export default withTranslation('translations')(PrivacySettingsModal)
