import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import dangerIcon from './i/danger.svg'

import useUserSubscriptions from 'hooks/useUserSubscriptions'
import useRouter from 'hooks/useRouter'

import { FEATURE_TYPES } from 'common/constants/subscription'

import { MODALS } from 'components/ModalManager/constants'

import useModalManager from 'hooks/useModalManager'

import List from './components/List/List'
import ListItem from './components/ListItem/ListItem'
import DangerMessage from './components/DangerMessage/DangerMessage'

import useLimits from './hooks/useLimits/useLimits'

import styles from './OrganizationLimits.module.scss'

const OrganizationLimits = () => {
    const { t } = useTranslation()
    const { constraints } = useUserSubscriptions()
    const { historyPush } = useRouter()
    const { openModal } = useModalManager()

    const { isHasDanger, isHasReached, dangerTypes, reachedTypes, limits } = useLimits(constraints)

    return (
        <div className={styles.organizationLimits}>
            <h4 className={styles.title}>
                {t('Activity')}
                {isHasDanger && (
                    <div className={styles.titleIcon}>
                        <img src={dangerIcon} alt="danger" />
                    </div>
                )}
            </h4>
            {isHasReached && (
                <DangerMessage>
                    {reachedTypes.length > 1 ? (
                        <Trans
                            i18nKey="You have reached the limits of your subscription. <lnk>Learn more</lnk>"
                            components={{
                                lnk: <a href="https://help.interacty.me/content/activity-and-limits" target="_blank" />,
                            }}
                        />
                    ) : (
                        <>
                            {reachedTypes[0] === FEATURE_TYPES.REVIEWS_COUNT && (
                                <Trans
                                    i18nKey="You have reached the limit of project views per month. <lnk>Learn more</lnk>"
                                    components={{
                                        lnk: <span onClick={() => openModal(MODALS.VIEWS_LIMITS_MODAL)} />,
                                    }}
                                />
                            )}
                            {reachedTypes[0] === FEATURE_TYPES.STORAGE_SIZE && (
                                <Trans
                                    i18nKey="Account storage is full. <lnk>Learn more</lnk>"
                                    components={{
                                        lnk: <span onClick={() => openModal(MODALS.STORAGE_LIMITS_MODAL)} />,
                                    }}
                                />
                            )}
                            {reachedTypes[0] === FEATURE_TYPES.PROJECTS_COUNT && (
                                <Trans
                                    i18nKey="You have reached the limit of projects. <lnk>Learn more</lnk>"
                                    components={{
                                        lnk: <span onClick={() => openModal(MODALS.PROJECTS_LIMITS_MODAL)} />,
                                    }}
                                />
                            )}
                        </>
                    )}
                </DangerMessage>
            )}
            <List>
                {limits.map(item => (
                    <ListItem key={item.type} {...item} />
                ))}
            </List>
            {isHasDanger && (
                <div className={styles.button}>
                    {dangerTypes.length > 1 ? (
                        <button
                            className="button"
                            data-variant="primary-light"
                            data-size="md"
                            onClick={() => historyPush('/billing-and-payments?tab=subscription')}
                        >
                            {t('Upgrade plan')}
                        </button>
                    ) : (
                        <>
                            {dangerTypes[0] === FEATURE_TYPES.REVIEWS_COUNT && (
                                <button
                                    className="button"
                                    data-variant="primary-light"
                                    data-size="md"
                                    onClick={() => historyPush('/billing-and-payments?tab=subscription')}
                                >
                                    {t('Get more views')}
                                </button>
                            )}
                            {dangerTypes[0] === FEATURE_TYPES.STORAGE_SIZE && (
                                <button
                                    className="button"
                                    data-variant="primary-light"
                                    data-size="md"
                                    onClick={() => historyPush('/billing-and-payments?tab=subscription')}
                                >
                                    {t('Increase storage')}
                                </button>
                            )}
                            {dangerTypes[0] === FEATURE_TYPES.PROJECTS_COUNT && (
                                <button
                                    className="button"
                                    data-variant="primary-light"
                                    data-size="md"
                                    onClick={() => historyPush('/billing-and-payments?tab=subscription')}
                                >
                                    {t('Get more projects')}
                                </button>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default OrganizationLimits
