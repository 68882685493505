import React, { useState } from 'react'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import { cloneDeep } from 'lodash'
import useKeypress from 'react-use-keypress'

import { encodeStringsInObject } from 'common/utils/objectStringsAction'

import Toast from 'components/Toast/Toast'

import CodeIcon from './svg/Code'

import styles from './Devtools.module.scss'

const JSON_TYPES = {
    BLOCK: 'BLOCK',
    PROJECT: 'PROJECT',
}

const Devtools = ({ selectedBlockJson, projectJson }) => {
    const [isActive, setIsActive] = useState(false)
    const [isOpened, setIsOpened] = useState(false)

    useKeypress('F1', () => {
        setIsActive(prevState => !prevState)
    })

    const onCopyJSON = type => {
        switch (type) {
            case JSON_TYPES.BLOCK: {
                copy(JSON.stringify(encodeStringsInObject(cloneDeep(selectedBlockJson))))
                Toast('success', { title: 'Block JSON copied to clipboard!' })
                break
            }
            case JSON_TYPES.PROJECT: {
                copy(JSON.stringify(encodeStringsInObject(cloneDeep(projectJson))))
                Toast('success', { title: 'Project JSON copied to clipboard!' })
                break
            }
            default:
                break
        }
    }

    if (!isActive) return null

    return (
        <div className={classNames(styles.devtools, { [styles.isOpenedDevtools]: isOpened })}>
            <div className={styles.topBar}>
                <div className={styles.tongue} onClick={() => setIsOpened(prevState => !prevState)}>
                    <div className={styles.tongueCircle}>
                        <CodeIcon />
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <ul className={styles.list}>
                    <li
                        onClick={() => onCopyJSON(JSON_TYPES.BLOCK)}
                        className={classNames(styles.item, { [styles.isDisabledItem]: !selectedBlockJson })}
                    >
                        Copy block JSON
                    </li>
                    <li onClick={() => onCopyJSON(JSON_TYPES.PROJECT)} className={styles.item}>
                        Copy project JSON
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Devtools
