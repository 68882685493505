import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import Modal from 'components/Modal/Modal'

import CURRENCIES from 'common/constants/currencies'

import ChooseBlock from './components/ChooseBlock/ChooseBlock'

import individualIcon from './i/individual.svg'
import companyIcon from './i/company.svg'
import arrowRight from './i/arrowRight.svg'

import styles from './ChooseCheckoutModal.module.scss'

const ChooseCheckoutModal = ({ onClose, onCheckout }) => {
    const { t } = useTranslation()
    const { paymentCurrency } = useSelector(state => state)
    const [isLoading, setIsLoading] = useState(false)
    const [isShowRussianNote, setIsShowRussianNote] = useState(false)
    const [isBusinessPayment, setIsBusinessPayment] = useState(false)

    useEffect(() => {
        if (paymentCurrency === CURRENCIES.RUB) setIsShowRussianNote(true)
    }, [])

    return (
        <Modal isShowCloseIcon onClose={() => onClose()} closeIconClassName={styles.closeIcon}>
            <div className={styles.content}>
                {isShowRussianNote ? (
                    <>
                        <h3 className={styles.title}>{t('Скоро: прием платежей в России')}</h3>
                        <p className={styles.text}>
                            Совсем скоро на Interacty появится возможность оплачивать подписку в рублях российской
                            картой – наша команда активно работает над этим. Сейчас вы можете оплатить иностранной
                            картой в USD или дождаться обновления.
                        </p>
                        <div className={styles.buttonBox}>
                            <button
                                className={classNames('button', styles.buttonRussian)}
                                data-size="lg"
                                data-variant="primary"
                                disabled={isLoading}
                                onClick={() => setIsShowRussianNote(false)}
                            >
                                {t('Оплатить иностранной картой в USD')}
                                <div className={styles.buttonIconBox}>
                                    <img src={arrowRight} alt="" />
                                </div>
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <h3 className={styles.title}>{t('Choose how you want to pay')}</h3>
                        <ul className={styles.chooseBlocks}>
                            <ChooseBlock
                                icon={individualIcon}
                                title={t('As an individual')}
                                description={t('Simple payment option')}
                                isSelected={!isBusinessPayment}
                                onSelect={() => setIsBusinessPayment(false)}
                            />
                            <ChooseBlock
                                icon={companyIcon}
                                title={t('As a company')}
                                description={t('More invoice details')}
                                isSelected={isBusinessPayment}
                                onSelect={() => setIsBusinessPayment(true)}
                            />
                        </ul>
                        <div className={styles.buttonBox}>
                            <button
                                className={classNames('button', styles.button)}
                                data-size="lg"
                                data-variant="primary"
                                disabled={isLoading}
                                onClick={async () => {
                                    try {
                                        setIsLoading(true)
                                        await onCheckout(isBusinessPayment)
                                    } catch (err) {
                                        console.error(err)
                                    } finally {
                                        setIsLoading(false)
                                    }
                                }}
                            >
                                {t('Proceed to checkout')}
                                <div className={styles.buttonIconBox}>
                                    <img src={arrowRight} alt="" />
                                </div>
                            </button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default ChooseCheckoutModal
