import React, { useRef, useEffect, useState } from 'react'

import { scrollContainerToElementById } from 'utils/scrollContainerToElementById'

import TabContent from '../../../components/TabContent/TabContent'

import Header from './components/Header/Header'
import Item from './components/Item/Item'
import AddButton from './components/AddButton/AddButton'

import styles from './Items.module.scss'

const Items = ({ errors, structure, onUpdate, onRemove, onAdd, scrollableItem }) => {
    const [prevItemsCount, setPrevItemsCount] = useState(structure.length)
    const scrollableBlockNodeRef = useRef(null)

    useEffect(() => {
        if (!scrollableItem) return
        scrollContainerToElementById(scrollableBlockNodeRef, `item_${scrollableItem.id}`)
    }, [scrollableItem])

    useEffect(() => {
        setPrevItemsCount(structure.length)
        if (structure.length > prevItemsCount) {
            const elementId = `item_${structure[structure.length - 1].id}`
            scrollContainerToElementById(scrollableBlockNodeRef, elementId)
        }
    }, [structure])

    return (
        <TabContent scrollableNodeRef={scrollableBlockNodeRef} isHideHeader>
            <div className={styles.items}>
                <Header itemsCount={structure.length} onAdd={onAdd} />
                <ul className={styles.list}>
                    {structure.map((item, index) => (
                        <Item
                            key={item.id}
                            index={index}
                            errors={errors?.get(item.id)}
                            item={item}
                            itemsCount={structure.length}
                            onUpdate={data => onUpdate(index, item.id, data)}
                            onRemove={() => onRemove(item.id)}
                        />
                    ))}
                </ul>
                <AddButton itemsCount={structure.length} onAdd={onAdd} className={styles.addButton} />
            </div>
        </TabContent>
    )
}

export default Items
