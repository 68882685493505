import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import LoaderEllipsis from 'components/LoaderEllipsis/LoaderEllipsis'

import { API__ANALYTICS } from 'api'

import { prettifyNumber } from '../../../../utils'

import styles from './BlockBaseStatistic.module.scss'

const BlockBaseStatistic = ({ projectId, blockId, dateRange }) => {
    const { t } = useTranslation()
    const [isReady, setIsReady] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [started, setStarted] = useState(0)
    const [completed, setCompleted] = useState(0)
    const [percent, setPercent] = useState(0)

    useEffect(() => {
        if (!dateRange) return
        setIsLoading(true)
        API__ANALYTICS.GET_BLOCK_BASE_STATISTICS(projectId, blockId, { dateRange })
            .then(statistic => {
                setCompleted(statistic.completed)
                if (statistic.completed > statistic.started) {
                    setPercent(100)
                    setStarted(statistic.completed)
                } else {
                    setPercent(statistic.completedPercent)
                    setStarted(statistic.started)
                }
                setIsReady(true)
            })
            .catch(err => console.error(err))
            .finally(() => setIsLoading(false))
    }, [dateRange])

    if (!isReady) return null

    return (
        <div className={styles.blockBaseStatistic}>
            {isLoading && (
                <div className={styles.loader}>
                    <LoaderEllipsis />
                </div>
            )}
            <div className={classNames(styles.wrapper, { [styles.isLoadingWrapper]: isLoading })}>
                <h2 className={styles.title}>{t('Block summary')}</h2>
                <div className={styles.content}>
                    <div className={styles.item}>
                        <p className={styles.itemTitle}>{t('Started ')}</p>
                        <p className={styles.itemCount}>{prettifyNumber(started)}</p>
                    </div>
                    <div className={styles.item}>
                        <p className={styles.itemTitle}>{t('Completed')}</p>
                        <p className={styles.itemCount}>
                            {prettifyNumber(completed)}
                            <span className={styles.itemPostfix}>{percent}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockBaseStatistic
